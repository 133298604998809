<template>

	<div class="Customer_Information_form custom-modal-wrap">
		<el-dialog :title="modal_title" :visible.sync="shows" :close-on-click-modal="false"  :close-on-press-escape="false"  @closed="onclosed" v-loading="loading">
			<div class="form_box">
				<div class="form_flex">
					<div class="lefts">
						福利名称
					</div>
					<div class="rights">
						<el-input v-model="form.title" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="form_flex">
					<div class="lefts">
						福利礼品
					</div>
					<div class="rights">
						<el-input v-model="form.content" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="form_flex">
					<div class="lefts">
						获取条件
					</div>
					<div class="rights">
						<el-input v-model="form.day" placeholder="请输入" style="width: 244px;"></el-input> 天
					</div>
				</div>
				<div class="form_flex">
					<div class="lefts">
						福利截止时间
					</div>
					<div class="rights">
						<!-- <el-input v-model="form.cardId" placeholder="请输入"></el-input> -->
						<el-date-picker v-model="form.stopTime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
						</el-date-picker>
					</div>
				</div>
				<div class="form_flex">
					<div class="lefts no-required">
						备注说明
					</div>
					<div class="rights">
						<el-input v-model="form.remark" type="textarea" placeholder="请输入"></el-input>
						<div class="upload-wrap">
							<!-- <el-upload class="upload-item" :file-list="fileList" :action="uploadActionImport" name="file"
								:data="uploadExtraData" :on-success="onSuccessUpload" :limit="1" :on-remove="handleRemove">
								<button class="btn-upload"></button>
							</el-upload> -->
							<el-upload class="upload-demo" multiple :limit="3" :file-list="fileList" action="">
							</el-upload>

							<div class="btn-box" style="margin-top: 0px;">
								<button class="btn-upload" @click="handle_up()">上传附件</button>
								
								<upload_tip />
							</div>
						</div>
					</div>
				</div>


				<div class="btn_flex">
					<div class="btns2" @click="handle_ends" v-if="!form.id">提交</div>
					<div class="btns2" @click="handle_ends" v-if="form.id">提交</div>
					<div class="btns1" @click="handle_close">取消</div>
				</div>
			</div>
		</el-dialog>


		<upload_img ref="upload_img"></upload_img>
	</div>
</template>
<script>
	import upload_img from '@/components/upload/upload.vue'

	export default {
		name: "Customer_Information_form",
		components: {
			upload_img
		},
		props: {

		},
		data() {
			return {
				modal_title: '新增福利',
				shows: false,
				form: {
					id: 0,
					title: '',
					content: '',
					day: '',
					attact: '',
					remark: '',
					stopTime: '',
				},
				file_info: {},

				fileList: [],
			};
		},
		computed: {
			uploadActionImport() {
				let ret =
					process.env.NODE_ENV != "production" ?
					"/api/upload" :
					this.uploadPrefix + "/api/upload";
				return ret;
			},

			uploadExtraData() {
				let token = localStorage.getItem('token')
				return {
					token: token
				};
			},
		},

		watch: {

		},

		created() {


		},

		methods: {
			onclosed() {
				this.form = {
					id: 0,
					title: '',
					content: '',
					day: '',
					attact: '',
					remark: '',
					stopTime: '',
				}
			},


			handle_up() {
				this.$refs.upload_img.uploadFile()
			},
			upload_url(info) {

				this.file_info = {
					name: info.name,
					url: info.path,
				}

				this.fileList = [this.file_info]
			},

			init(row) {
				this.shows = true
				if (row) {
					this.modal_title = '编辑福利'
					this.query_detail(row)
					let data = row
					this.form = {
						id:row.id,
						title: data.title || '',
						content: data.content || '',
						day: data.day || '',
						attact: data.attact || '',
						remark: data.remark || '',
						stopTime: data.stopTime || '',
					}

				} else {
					this.modal_title = '新增福利'
				}
			},

			query_detail(row) {
				// this.$api("getAdmin", {
				// 	id: row.id
				// }, "get").then((res) => {
				// 	if (res.code == 200) {
				// 		let data = res.data;
				// 		this.form = {
				// 			id: data.id || 0,
				// 			name: data.name || '',
				// 			role_id: data.role_id || '',
				// 			username: data.username || '',
				// 			password: '',
				// 		}
				// 	} 
				// });
			},

			// 新增打开
			handle_add_open(row) {
				this.title = ''
				this.shows = true
			},
			//修改打开
			handle_modify_open(row) {
				this.title = ''
				let rows = JSON.parse(JSON.stringify(row))
				this.form = rows
				console.log('row', row)

				this.shows = true
			},
			//关闭    
			handle_close() {
				this.shows = false
			},
			//确认添加修改
			handle_ends() {
				if (!this.form.title) {
					alertErr('请输入福利名称')
					return
				}
				if (!this.form.content) {
					alertErr('请输入福利礼品')
					return
				}
				if (!this.form.day) {
					alertErr('请输入获取条件')
					return
				}
				if (!this.form.stopTime) {
					alertErr('请选择福利截止时间')
					return
				}
				// if (!this.file_info.name) {
				// 	alertErr('请上传附件')
				// 	return
				// }
				let attact=''
				if(this.file_info.name){
					attact=JSON.stringify(this.file_info)
				}

				this.loading = true
				this.$api("editGift", {
					...this.form,
					attact: attact 
				}, "post").then((res) => {
					this.loading = false
					alert(res)
					if (res.code == 200) {
						this.shows = false
						this.$emit("confirm");
					}
				});
			},


			onSuccessUpload(res, file) {
				console.log("上传结果 res", res);
				console.log("上传结果 file", file);
				let {
					code,
					data,
					msg
				} = res;
				if (code == 200) {

				}
			},

			//移除上传
			handleRemove(file, fileList, other) {
				console.log("删除图片 file", file);
				console.log("删除图片 fileList", fileList);
				console.log("删除图片 other", other);
				// let index = this.list_upload.findIndex((item) => item.uid == file.uid);
				// this.list_upload.splice(index, 1);

				this.$emit("parentHandleUpload", {
					type: "删除成功",
					colName: this.col,
					file,
					res: file.response,
				});

				// this.$emit("parentHandleUploadDelete", {
				//   file,
				//   colName: this.col,
				// });
			},


		},



	};
</script>

<style scoped lang="less">
	.upload-wrap {
		margin-top: 20px;
		margin-bottom: 40px;

		.btn-upload {
			min-width: 104px;
			height: 40px;
			background: #0060fa;
			border-radius: 4px;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #ffffff;
			
			background: linear-gradient( 90deg, #157DE9 0%, #3697FD 100%);
			border-radius: 4px 4px 4px 4px;
		}
	}



	.custom-modal-wrap {

		/deep/ .el-dialog .el-dialog__header {
			background: #fff !important;
		}
	}

	/deep/ .el-dialog__body {
		padding: 30px 50px 0 50px;
	}

	.btn_flex {
		padding: 25px 0;
		display: flex;
		justify-content: center !important;
	}


	.Customer_Information_form {
		/deep/.el-dialog {
			width: 680px;
		}

		/deep/.el-dialog__header {
			background: #F2F5FA !important;
		}

		.form_box {
			.form_flex {
				display: flex;
				align-items: center;
				align-items: flex-start;
				margin-bottom: 16px;

				.lefts {
					// text-align: right;
					line-height: 40px;
					width: 120px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;

					&:not(.no-required)::before {
						content: '*';
						display: inline-block;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 16px;
						color: #FC5F03;
					}
				}

				.rights {
					// width: 446px;
					flex: 1;
					height: 40px;
					height: auto;
					// background: #F2F5FA;
					border-radius: 4px 4px 4px 4px;

					input {
						padding: 0 12px;
						width: 100%;
						height: 40px;
					}

					/deep/.el-input {
						width: 100%;

						.el-input__inner {
							// background: #F2F5FA;
							// border: 0;
						}
					}

					.el-select {
						width: 100%;
					}
				}
			}

			.btn_flex {
				display: flex;
				align-items: center;
				justify-content: end;

				.btns1 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;
					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #FFFFFF;
					border-radius: 4px 4px 4px 4px;
					border: 1px solid #B8B8B8;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #6A6A6A;
				}

				.btns2 {
					cursor: pointer;
					text-align: center;
					margin: 0 11px;

					width: 91px;
					height: 38px;
					line-height: 38px;
					background: #2373C8;
					border-radius: 4px 4px 4px 4px;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FFFFFF;
				}
			}
		}

	}
</style>